<template>
  <transition
    name="modal-fade"
    @leave="leave"
  >
    <div v-if="isOpen" class="fixed inset-0 flex items-center justify-center bg-black bg-opacity-20" @click="closeModal">
      <div id="modal_window" class="p-4 sm:p-6 rounded-lg shadow-lg sm:w-3/4 lg:w-1/2 animate__animated animate__fadeInUp bg-sky-100" @click.stop>
        <h2 class="text-lg mb-4 font-bold text-left text-sky-900">
          <i class="material-symbols--search text-md" style="vertical-align: middle; line-height: 1;" />
          订阅使用情况
        </h2>
        <!-- Close Icon -->
        <button @click="closeModal" class="absolute top-0 right-0 px-2.5 py-1.2 text-sky-100 bg-sky-700 hover:bg-sky-800 focus:outline-none rounded-tr-lg rounded-bl-lg">
          <span class="material-symbols--close-small-outline-rounded">close</span>
        </button>
        <div class="mb-4 text-left text-sky-900 overflow-x-auto">
          <slot>
            <table class="table-auto w-full text-sky-900">
              <thead>
                <tr>
                  <th class="px-1 py-1">用户名</th>
                  <th class="px-1 py-1">Token</th>
                  <th class="px-1 py-1 pl-2">使用次数</th>
                  <th class="px-1 py-1 hidden sm:table-cell">访问IP</th>
                  <th class="px-1 py-1  hidden sm:table-cell">创建时间</th>
                  <th class="px-1 py-1">操作</th>
                </tr>
              </thead>
              <tbody>
                <tr 
                  :id="'data_'+item.id"
                  v-for="item in content" 
                  :key="item.id"
                >
                  <td class="px-1 py-1" style="display:none">{{ item.id }}</td>
                  <td class="px-1 py-1 truncate-content">{{ item.username }}</td>
                  <td class="px-1 py-1 truncate-content" :title="item.token">{{ item.token }}</td>
                  <td class="px-1 py-1 pl-2 truncate-content">{{ item.count }}</td>
                  <td class="px-1 py-1 hidden sm:table-cell truncate-content">{{ item.last_ip || '未知' }}</td>
                  <td class="px-1 py-1 hidden sm:table-cell truncate-content">{{ formatTimestamp(item.ts) || '未知' }}</td>
                  <td class="px-1 py-1">
                    <button 
                      :id="'copy_btn_'+item.id"
                      @click="copyLink(item.token, item.id)"
                      title="复制链接"
                      class="tabler--copy hover:text-sky-800 text-sky-900 font-semibold py-1 px-2 mr-1 rounded">
                    </button>
                    <button 
                      @click="deleteSubscription(item.id)"
                      :id="'btn_delete_subscription_' + item.id"
                      title="删除订阅"
                      class="typcn--delete hover:text-sky-800 text-sky-900 font-semibold py-1 px-2 rounded">
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </slot>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import 'animate.css';
import api from '../api'; // 导入封装好的API模块

export default {
  name: 'ShowSubscriptionModal',
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    content: {
      type: Array,
      default: () => [],
      required: true,
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    },
    leave(el) {
      const modal_element = el.querySelector('#modal_window');
      if (modal_element) {
        modal_element.classList.remove('animate__fadeInUp');
        modal_element.classList.add('animate__fadeOutDown');
      }
    },
    formatTimestamp(ts) {
      if (!ts) return null;
      const timestamp = Math.floor(parseFloat(ts) * 1000);
      const date = new Date(timestamp);

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需加1
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}/${month}/${day}`;
    },
    copyLink(token, id){
      var currentDomain = window.location.origin;
      var subscribe_link = currentDomain + '/subscribe?token=' + token;
      var copyElement = document.getElementById(`copy_btn_${id}`);
      copyElement.classList.add("animate__animated", "animate__flipInX");
      navigator.clipboard.writeText(subscribe_link).then(() => {
        console.error('复制成功');
        copyElement.classList.remove('tabler--copy');
        copyElement.classList.add('tabler--copy-check');
      }).catch(err => {
        console.error('复制失败:', err);
        copyElement.classList.remove('tabler--copy');
        copyElement.classList.add('tabler--copy-x');
      });
    },
    async deleteSubscription(id) {
      var button_element = document.getElementById("btn_delete_subscription_" + id);
      button_element.disabled = true;
      button_element.classList.add('rotate');
      var data = {
          token: this.$store.state.token,
          id: id
      };
      const response = await api.deleteSubscription(data);
      if (response.data.result) {
          var this_data = document.getElementById("data_"+id);
          this_data.remove();
      } else {
          this.showInfo('订阅删除失败', response.data.err);
          button_element.disabled = false;
          button_element.classList.remove('rotate');
      }
    },
  },
  mounted() {
    console.log(this.content)
  }
};
</script>

<style scoped>
.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.5s;
}
.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}

.fixed {
  z-index: 9999; /* 确保背景在模态框下 */
}

.truncate-content {
  white-space: nowrap; /* 强制内容不换行 */
  overflow: hidden;    /* 隐藏超出的内容 */
  text-overflow: ellipsis; /* 显示省略号 */
  max-width: 8ch;     /* 限制显示宽度 */
}

/* 添加响应式样式 */
@media (max-width: 640px) {
  #modal_window {
    width: 90%; /* 手机端宽度 */
  }
  h2 {
    font-size: 1.25rem; /* 标题大小 */
  }
  .text-sm {
    font-size: 0.875rem; /* 调整字体大小 */
  }
}
</style>
